import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';

@Component({
  selector: 'cxr-prime-calendar',
  templateUrl: './prime-calendar.component.html',
  styleUrls: ['./prime-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrimeCalendarComponent),
      multi: true,
    }
  ]
})
export class PrimeCalendarComponent implements ControlValueAccessor, OnChanges {

  constructor (private datePipe: DatePipe) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['min']) {
      let val = this.min.split("-");
      this.minDate = new Date();
      this.minDate.setHours(0, 0, 0, 0);
      this.minDate.setFullYear(val[0]);
      this.minDate.setMonth(val[1] - 1);
      this.minDate.setDate(val[2]);
    }
    if (changes['max']) {
      let val = (this.max).split("-");
      this.maxDate = new Date();
      this.maxDate.setHours(0, 0, 0, 0);
      this.maxDate.setFullYear(val[0]);
      this.maxDate.setMonth(val[1] - 1);
      this.maxDate.setDate(val[2]);
    }
  }


  dateFormat: string = GetUserObject.GetPickerDateFormat();
  placeHolder: string = GetUserObject.GetDateFormat();

  @Input("min") public min;
  @Input("max") public max;
  @Input("readonly") public readonly: boolean;
  //@Input()
  dateValue: any;

  public minDate: Date;
  public maxDate: Date;

  private onChange = (value: any) => { };
  private onTouched = () => { };
  writeValue(value: any) {
    // console.log(this.dateValue, '1');
    ;
    if (value != null && value) {

      let val = value.split("-");
      //  console.log(val, 'split');

      // this.dateValue.setFullYear(val[0]);

      // this.dateValue.setMonth((val[1] - 1));

      // this.dateValue.setDate(val[2]);

      // this.dateValue.setHours(0, 0, 0, 0);;

      this.dateValue = new Date(val[0], (val[1] - 1), val[2], 0, 0, 0);


    }
    else {
      this.dateValue = null;
    }
  }

  setDisabledState(disabled: boolean) {
    // this.disabled = disabled;
  }

  onValueChange(event) {
    this.onChange(this.datePipe.transform(event, "yyyy-MM-dd"));

    this.onTouched();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
