import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  public invoiceDetails: any;
  public bookingDetails: any;

  constructor (private router: Router,
    private _location: Location,
    private _http: HttpClient) { }

  isPreview: boolean;

  printDocument(invoiceId: any, invoiceDetails: any, printPreview: boolean = false) {

    if (printPreview) {
      this.isPreview = true;
      localStorage.setItem('InvoiceDetails', JSON.stringify(invoiceDetails));
      const url = this.router.createUrlTree(['/invoice-print', 0]);
      window.open(url.toString(), '_blank');
    }
    else {
      this.isPrinting = true;
      this.invoiceDetails = invoiceDetails;
      this.router.navigate(['/invoice-print', invoiceId]);
    }
  }

  printBooking(bookingDetails: any) {
    this.isPrinting = true;
    this.bookingDetails = bookingDetails;
    this.router.navigate(['/booking-print']);
  }


  onDataReady(isPreview) {
    setTimeout(() => {
      window.print();
      // this.isPrinting = false;
      localStorage.removeItem('InvoiceDetails');
      if (isPreview)
        window.close();
      else
        this._location.back();
      // this.router.navigate([{ outlets: { print: null } }]);
    }, 2000);
  }

  openPrintBooking() {
    setTimeout(() => {
      window.print();
      // this.isPrinting = false;
      this._location.back();
      // this.router.navigate([{ outlets: { print: null } }]);
    }, 2000);
  }
}
