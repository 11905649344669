import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NumberFormatPipe } from 'src/app/shared/_pipes/number-format.pipe';

@Injectable({
    providedIn: 'root'
})
export class RegisterCommentService {
    constructor (private translate: TranslateService,
        private _numberFormatPipe: NumberFormatPipe,
    ) { }

    async getLocalisedComments(data: Array<any>) {

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.PreComment) {
                let amount = element.In > 0 ? element.In : element.Out;
                await this.translate.get(element.PreComment, { currency: element.CurrencyName }).subscribe(async key => {
                    element['PreCommentLocale'] = key;
                    // data[index]['comment'] = key;
                    if (element.isReceived) {
                        if (element.isCash) {
                            data[index]['comment'] = key + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isCheque) {
                            this.translate.get(['Amount', 'Cheque Type', 'Cheque Number', 'To the order of']).subscribe(keys => {
                                data[index]['comment'] = key + "\n"
                                    + keys['Amount'] + " : " + this._numberFormatPipe.transform(amount) + " " + element.Currency +
                                    "\n"
                                    + keys['Cheque Type'] + " : " + (element.ChequeFormData.TypeOfCheque == null ? " - " : element.ChequeFormData.TypeOfCheque) + "\n" + keys['Cheque Number'] + " : " +
                                    (element.ChequeFormData.ChequeNumber == null ? " - " : element.ChequeFormData.ChequeNumber) + "\n" +
                                    keys['To the order of'] + " : " + element.ChequeFormData.PayableTo;;
                            });
                        }
                        else if (element.isAccount) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isTransfer) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isEWire) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                    }
                    else {
                        if (element.isCash) {
                            if (element.isTransfer) {
                                data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                            }
                            else {
                                data[index]['comment'] = key + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                            }
                        }
                        else if (element.isCheque) {
                            this.translate.get(['Amount', 'Cheque Type', 'Cheque Number', 'To the order of']).subscribe(keys => {
                                data[index]['comment'] = key + "\n"
                                    + keys['Amount'] + " : " + this._numberFormatPipe.transform(amount) + " " + element.Currency +
                                    "\n"
                                    + keys['Cheque Type'] + " : " + (element.ChequeFormData.TypeOfCheque == null ? " - " : element.ChequeFormData.TypeOfCheque) + "\n" + keys['Cheque Number'] + " : " +
                                    (element.ChequeFormData.ChequeNumber == null ? " - " : element.ChequeFormData.ChequeNumber) + "\n" +
                                    keys['To the order of'] + " : " + element.ChequeFormData.PayableTo;;
                            });
                        }
                        else if (element.isAccount) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isEWire) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isWire) {
                            await this.translate.get(['Beneficiary Full Name', 'Beneficiary Address', 'Beneficiary Phone', 'Amount', 'Bank Name', 'Bank Address', 'SWIFT', 'Account No.']).subscribe(keys => {
                                data[index]['comment'] = key + "\n"
                                    + keys['Beneficiary Full Name'] + " : " + element.WireFormData?.BeneficiaryName +
                                    "\n"
                                    + keys['Beneficiary Address'] + " : " + (element?.BeneficiaryStreet ?? "") + " " + (element?.BeneficiaryCity ?? "") + " " + (element?.BeneficiaryStateProvince ?? "") + " " + (element?.BeneficiaryCountry ?? "") + " " + (element?.BeneficiaryZipCode ?? "") +
                                    "\n"
                                    + keys['Beneficiary Phone'] + " : " + element.WireFormData?.BeneficiaryPhone +
                                    "\n"
                                    + keys['Amount'] + " : " + this._numberFormatPipe.transform(amount) + " " + element.Currency +
                                    "\n"
                                    + keys['Bank Name'] + " : " + element.WireFormData?.BankName +
                                    "\n"
                                    + keys['Bank Address'] + " : " + (element?.BankingAddress ?? "") + " " + (element?.BankStreet ?? "") + " " + (element?.BankCity ?? "") + " " + (element?.BankStateProvince ?? "") + " " + (element?.BankCountry ?? "") + " " + (element?.BankZipCode ?? "") +
                                    "\n"
                                    + keys['SWIFT'] + " : " + element.WireFormData?.SwiftBICTransit +
                                    "\n"
                                    + keys['Account No.'] + " : " + element.WireFormData?.AccountNumber;
                            });
                        }
                        else if (element.isTransfer) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + "" + element.Currency;
                        }
                        else {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount);
                        }
                    }

                    if (data.length - 1 == index) {
                        return Promise.resolve(data);
                    }

                });
            }
        }
        return Promise.resolve(data);
    }

}
